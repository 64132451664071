<template>
  <div class="conversion-container">
    <div :style="explorerChromeSafari ? 'height: calc(100vh - 19rem) !important;overflow-y: auto;' : 'height: calc(100vh - 13rem) !important;overflow-y: auto;'">
      <!-- 无收货地址 -->
      <div
        v-if="addressList.length == 0 && selectCollection.exchange != 2  && selectCollection.exchange != 3"
        class="address-set row-center"
        @click="addAddress()"
      >
        <p class="address-set-title">收货地址</p>
        <p class="address-set-tip">暂无收货地址, <a class="address-set-a">去添加</a></p>
      </div>
      <!-- 有收货地址 -->
      <div
        v-if="addressList.length != 0 && selectCollection.exchange != 2  && selectCollection.exchange != 3"
        class="address-nomal row-between"
        @click="isShow = true;"
      >
        <div style="margin-left: 16px;">
          <p class="address-nomal-title">收货地址</p>
          <p
            style="margin-top:15px;"
            class="address-nomal-name"
          >
            <span>{{ defaultAdress.realName }}</span>
            <span style="margin-left: 10px;">{{ defaultAdress.phone }}</span>
          </p>
          <p
            style="margin-top:5px;"
            class="address-nomal-name"
          >{{defaultAdress.province}}{{defaultAdress.city}}{{defaultAdress.district}}{{defaultAdress.detail}}</p>
        </div>
        <img
          class="next-img"
          :src="require('../assets/icon/next.png')"
        />
      </div>
      <!-- 虚拟商品兑换 -->
      <div
        v-if="selectCollection.exchange == 2 || selectCollection.exchange == 3"
        class="address-set-exchange row-center"
      >
        <p class="address-set-title">虚拟物品兑换后，将自动发货，请在“兑换订单”中查看详细信息，无须您填写收货地址</p>
      </div>
      <!-- 选择兑换藏品 -->
      <div class="select-collection">
        <p class="select-collection-title">选择兑换藏品</p>
        <!-- 藏品选择区 -->
        <div
          @click="selectConversionCollection()"
          class="collection-select-content row-between"
        >
          <div
            style="margin-left: 16px;"
            class="collection-select-container"
            :class="selectCollection.id ? 'row-start' : 'row-center'"
          >
            <img
              v-if="selectCollection.id"
              :src="selectCollection.image"
            />
            <div
              v-if="selectCollection.id"
              class="col-start"
            >
              <p class="item-content-name">{{ selectCollection.name }}</p>
              <p class="item-content-unique">ID:{{ selectCollection.showId && selectCollection.showId != '0' ? selectCollection.showId : selectCollection.uniqueCode }}</p>
            </div>
            <p
              v-if="!selectCollection.id"
              class="collection-select-no-collection"
            >选择藏品</p>
          </div>
          <img
            class="next-img"
            :src="require('../assets/icon/next.png')"
          />
        </div>
        <div class="collection-select-line"></div>
        <div class="collection-select-tip">
          <p>说明：</p>
          <p>1.通过兑换功能可将对应的藏品兑换成实物或虚拟卡劵等</p>
          <p>2.提交兑换后可在“我的”-“订单”-“兑换订单”中查看订单、物流信息、卡劵信息、优惠券信息等</p>
          <p>3.兑换成功后，平台将收回对应藏品。</p>
        </div>
      </div>
    </div>
    <!-- 新增地址按钮 -->
    <div
      class="row-center add-adress"
      @click="comfirmConversion()"
    >确认兑换</div>
    <!-- 立即购买 -->
    <van-popup
      v-model="isShow"
      closeable
      position="bottom"
      style="height: 70vh;background: #262626;position: fixed;bottom: 0px !important;left: 0;"
    >
      <p class="conversion-popup-title">选择收货地址</p>
      <div style="height:40vh;overflow-y:auto;">
        <div
          v-for="(i,j) in addressList"
          @click="selectDefaultAddress(i)"
          :key="j"
          class="row-between select-address-item"
        >
          <div>
            <p
              style="margin-top:15px;"
              class="address-nomal-name"
            >
              <span>{{ i.realName }}</span>
              <span style="margin-left: 10px;">{{ i.phone }}</span>
            </p>
            <p
              style="margin-top:5px;"
              class="address-nomal-name"
            >{{i.province}}{{i.city}}{{i.district}}{{i.detail}}</p>
          </div>
          <div v-if="defaultAdress.id != i.id"></div>
          <img
            v-else
            class="select-img"
            :src="require('../assets/icon/pay-success.png')"
          />
        </div>
      </div>
      <div
        class="row-center add-adress"
        @click="addAddress()"
      >新增地址</div>
    </van-popup>
    <el-dialog
      title="尚未设置资产密码"
      :visible.sync="donationVisible"
      @confirm="donationVisible = false"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="donationVisible = false">取 消</el-button>
        <div
          @click="$router.push('/resetPwd')"
          class="confirm-button row-center"
        >去设置</div>
      </span>
    </el-dialog>
    <van-popup
      v-model="pwdShow"
      closeable
      position="bottom"
      style="background: rgba(21, 21, 21, 0.85);height: 411px;"
    >
      <div>
        <p class="donation-popup-title">安全验证</p>
        <!-- 密码输入框 -->
        <p class="donation-popup-tip">验证资产密码</p>
        <van-password-input
          :value="pwdValue"
          :gutter="8"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="pwdValue"
          :show="showKeyboard"
          @blur="pwdShow = false"
        />
      </div>
    </van-popup>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <!-- 提示弹窗 -->
    <el-dialog
      :visible.sync="orderVisible"
      class="donation-dialog"
      @confirm="goOrderDetails()"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-tip-title"
      >{{ conversionTitle }}</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >{{ conversionTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="goOrderDetails()"
          class="donation-button row-center"
        >查看订单</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Vue from 'vue'
import md5 from 'js-md5';
import { Popup,PasswordInput,NumberKeyboard } from 'vant';
Vue.use(Popup);
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
export default {
  data () {
    return {
      orderVisible: false,
      conversionTitle: '',
      conversionTip: '',
      pwdValue: '',
      showKeyboard: true,
      donationVisible: false,
      authVisible: false,
      addressList: [],
      isShow: false,
      explorerChromeSafari: false,
      defaultAdress: {},
      selectCollection: {
        id: null,
      },
      pwdShow: false,
      orderId: ''
    }
  },
  watch: {
    pwdValue () {
      if (this.pwdValue.length === 6) {
        this.conversion()
      }
    }
  },
  mounted () {
    // 获取地址列表
    this.getAdressList()
    this.checkUserAgent()
    if (localStorage.getItem("conversionCollection")) {
      this.selectCollection = JSON.parse(localStorage.getItem("conversionCollection"))
    } else {
      this.selectCollection = []
    }

  },
  methods: {
    // 确认兑换
    comfirmConversion () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            if (!result.data.data.hasAssetsPassword) {
              this.donationVisible = true
            } else if (!result.data.data.hasRealname) {
              this.authVisible = true
            } else {
              this.pwdShow = true
            }
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 获取当前账户地址列表
    getAdressList () {
      api
        .get('address/list')
        .then(result => {
          if (result.data.success) {

            this.addressList = result.data.data
            // 判断缓存中是否有默认地址有则采用默认地址
            if (localStorage.getItem('defaultAdress')) {
              this.defaultAdress = JSON.parse(localStorage.getItem('defaultAdress'))
            } else {
              // 判断是否有默认地址，没有则取第一条作为默认地址
              if (this.addressList.length && !this.addressList.filter(i => i.isDefault == 1).length) {
                this.defaultAdress = this.addressList[0]
              } else {
                this.addressList.map(i => {
                  if (i.isDefault == 1) {
                    this.defaultAdress = i
                  }
                })
              }
            }

            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 兑换操作
    conversion () {
      api
        .post('user/verifyAssetsPassword',{ assetsPassword: md5(this.pwdValue) })
        .then(result => {
          if (result.data.success) {
            api
              .post('assets/exchange/submit',{ addressId: this.defaultAdress.id,skuNo: this.selectCollection.skuNo,uniqueCode: this.selectCollection.uniqueCode })
              .then(result => {
                if (result.data.success) {
                  this.pwdShow = false
                  this.orderId = result.data.data
                  localStorage.removeItem("conversionCollection")
                  this.conversionTitle = '兑换成功'
                  this.orderVisible = true
                } else {
                  this.$toast({
                    message: result.data.msg,
                    icon: require('../assets/icon/toast-error.png'),
                  });
                }
              })
          } else {
            this.pwdValue = ''
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })

    },
    selectDefaultAddress (i) {
      this.defaultAdress = i
      localStorage.setItem('defaultAdress',JSON.stringify(i))
      this.isShow = false
    },
    // 添加地址
    addAddress () {
      this.$router.replace('/addAdress?from=conversion')
    },
    // 去选择兑换藏品页面
    selectConversionCollection () {
      this.$router.push('/conversionCollection')
    },
    // 去合成订单
    goOrderDetails () {
      this.$router.replace('/exchangeDetails?orderId=' + this.orderId + '&type=exchange')
    },
    // 检查设备，适配
    checkUserAgent () {
      var explorer = navigator.userAgent

      if (/(micromessenger)/i.test(explorer)) {
        // 微信浏览器
        this.explorerChromeSafari = false
      } else if (/(MQQBrowser)/i.test(navigator.userAgent)) {
        // qq
        this.explorerChromeSafari = true
      } else if (/(?:Chrome|CriOS)/.test(explorer)) {
        // 谷歌
        this.explorerChromeSafari = true
      } else if (/(iPhone|iPad|iPod|iOS)/i.test(explorer)) {
        // 除上面3个浏览器外的ios设备
        this.explorerChromeSafari = true
      } else {
        // 其他设备
        this.explorerChromeSafari = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.conversion-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .address-nomal {
    width: 90vw;
    margin-left: 5vw;
    height: 106px;
    background: #262728;
    margin-top: 20px;
  }
  .select-address-item {
    width: 90vw;
    margin-left: 5vw;
    height: 90px;
    background: #262728;
    border-bottom: 1px solid #fefefe;
  }
  .address-nomal-title {
    font-size: 14px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    color: #fefefe;
  }
  .address-nomal-name {
    font-size: 13px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    color: #cccccc;
    white-space: wrap;
  }
  .next-img {
    width: 0.5rem;
    height: 0.8125rem;
    margin-right: 1rem;
  }
  .address-set-exchange {
    width: 90vw;
    margin-left: 5vw;
    height: 100px;
    background: #262728;
    margin-top: 20px;
    position: relative;
    .address-set-title {
      position: absolute;
      top: 30px;
      left: 16px;
      font-size: 14px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
    }
  }
  .address-set {
    width: 90vw;
    margin-left: 5vw;
    height: 106px;
    background: #262728;
    margin-top: 20px;
    position: relative;
    .address-set-title {
      position: absolute;
      top: 12px;
      left: 16px;
      font-size: 14px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
    }
    .address-set-tip {
      font-size: 13px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #cccccc;
      .address-set-a {
        color: #2fadfc;
      }
    }
  }
  .select-collection {
    width: 90vw;
    margin-left: 5vw;
    height: 273px;
    background: #262728;
    margin-top: 12px;
    padding-top: 12px;
    .select-collection-title {
      font-size: 14px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
      margin-left: 18px;
    }
    .collection-select-content {
      width: 100%;
      height: 111px;
      .collection-select-container {
        width: 80%;
        img {
          width: 80px;
          height: 80px;
          margin: 0 15px 0 0px;
          border-radius: 8px;
        }
        .item-content-name {
          font-size: 14px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #fefefe;
          margin-bottom: 15px;
        }
        .item-content-unique {
          font-size: 13px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #cccccc;
        }
        .collection-select-no-collection {
          font-size: 13px;
          font-family: Noto Sans S Chinese;
          font-weight: 400;
          color: #858585;
        }
      }
    }
    .collection-select-line {
      width: 96%;
      margin-left: 2%;
      height: 1px;
      background: #5e5e5e;
    }
    .collection-select-tip {
      margin-top: 17px;
      width: 90%;
      margin-left: 5%;
      font-size: 13px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #cccccc;
      p {
        margin-bottom: 5px;
      }
    }
  }
  .add-adress {
    width: 90vw;
    height: 46px;
    margin: 1.3125rem 5vw 1.4375rem 5vw;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    letter-spacing: 2px;
    cursor: pointer;
    background-size: cover;
  }
  .conversion-popup-title {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-family: Noto Sans S Chinese;
    font-weight: 500;
    color: #fefefe;
  }
  .select-img {
    width: 20px;
    height: auto;
  }
  .van-password-input__security li {
    background: #565656;
    border-radius: 0.25rem;
  }
  .van-number-keyboard {
    background: rgba(21, 21, 21, 0.85);
  }
  /deep/.van-number-keyboard .van-key {
    background-color: #646465;
    box-shadow: 0px 1px 0px 0px #000000;
    font-family: Helvetica;
    color: #ffffff;
  }
}
</style>